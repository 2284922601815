import Main from "./view/main";
import "./style/main.css";

function App() {
  return ( 
    <div className="AppBackground">
      <Main/>
    </div>
   );
}

export default App;